import { COLORS } from '../styles/colors';
import { lighten } from '@mui/material/styles';
const MUIAppTable = {
    MuiTableCell: {
        styleOverrides: {
            head: {
                height: '45px',
                "&.innerTable" : {
                    height : "36px"
                },
                backgroundColor: COLORS.PRIMARY,
                color: COLORS.WHITE,
                fontWeight : 600,
                borderLeft: `1px solid ${lighten(COLORS.PRIMARY, 0.9)} !important`,
                "&:first-of-type": {
                    borderLeft: "0px",
                    borderRadius: "4px 0px 0px 0px",
                },
                "&:last-of-type": {
                    borderRadius: "0px 4px 0px 0px",
                }
            },
            body: {
                borderLeft: `1px solid ${lighten(COLORS.PRIMARY, 0.9)} !important`,
            },
        },
    },


    MuiTableRow: {
        styleOverrides: {
            root: {
                '&:nth-of-type(even):not(.noht)': {
                    backgroundColor: lighten(COLORS.PRIMARY, 0.9),
                },
                '&:hover:not(.noht)': {
                    backgroundColor: lighten(COLORS.PRIMARY, 0.8),
                    cursor : "pointer"
                },
            }
        }
    },
    MuiTableCell: {
        styleOverrides: {
            root: {
                "&.headingPrimartLight" : {
                    backgroundColor: lighten(COLORS.PRIMARY, 0.8),
                    height:"32px",
                    lineHeight : "0px",
                    fontWeight : 600
                }
            }
        }
    }
}

export {
    MUIAppTable
}
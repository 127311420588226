import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { authorizeRequestAPI, unAuthorizeRequestAPI } from "../../helper/services/HTTPRequestAPISDK";
import { getFromLocalStorage, removeFromLocalStorage } from "../../helper/storage";
import { API_URLS } from "../../constants";

const user = getFromLocalStorage("user");

export const accessCodeAuthorize = createAsyncThunk(
    "login",
    async ({ url, payload, method, headers = null }) => {
        try {
            const data = await unAuthorizeRequestAPI(url, payload, method).catch(error => {
                throw error
            });
            return { ...data };
        } catch (error) {
            return Promise.reject(JSON.stringify(error));
        }
    }
);

export const logout = createAsyncThunk("logout", async () => {
    try {
        await authorizeRequestAPI(API_URLS.logout_url, {}, "get").catch(error => {
            throw error
        });
        removeFromLocalStorage('user');
        return
    } catch (error) {
        return Promise.reject(JSON.stringify(error));
    }
});

const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };
const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    extraReducers: {
        [accessCodeAuthorize.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [accessCodeAuthorize.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        [logout.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        },
    },
});
const { reducer } = authenticationSlice;
export default reducer;
import { Suspense } from "react";
import Header from '../components/header';
import Loader from "../components/loader";
import { SnackbarProvider } from 'notistack';

const AuthorizedLayout = (props) => {
    const { children, showHeader, } = props;
    return (
        <SnackbarProvider>
            <Suspense fallback={<Loader />}>
                {showHeader && <Header />}
                <main className="authorized-layout-main" >
                    {children}
                </main>
            </Suspense>
        </SnackbarProvider>
    );
}

export default AuthorizedLayout;


import { API_ROOT,SSO_LOGOUT,SSO_HOME } from "../../envrionment";

const baseURL = "/psttool/v1";
const SSO_LOGOUT_URL = SSO_LOGOUT;
const SSO_HOMR_URL = SSO_HOME;
const API_URLS = {
    search_form_field_url: `${baseURL}/search/form/fields`,
    search_result_url: `${baseURL}/search/elk/query`,
    search_result_export: `${baseURL}/search/elk/export`,
    searched_history_url: `${baseURL}/search/user/searched/history`,
    searched_history_archive_url: `${baseURL}/search/user/searched/archive`,
    searched_history_delete_url: `${baseURL}/search/user/searched/delete`,
    searched_one_patent_details_url: `${baseURL}/search/user/searched/one/patent/details`,
    pdf_display_url: `${baseURL}/pdf/display`,
    user_access_code_validate_url: `${baseURL}/validate/auth`,
    patent_family_details_url: `${baseURL}/patent/family/legal/status`,
    project_patent_images_count_url: `${baseURL}/patent/images/count`,
    project_patent_images_url: `${baseURL}/patent/images`,
    user_highlight_profile_save_url: `${baseURL}/user/highlight/save`,
    user_highlight_profile_update_url: `${baseURL}/user/highlight/update`,
    user_highlight_profile_get_url: `${baseURL}/user/highlight/get`,
}

export {
    API_URLS,
    SSO_LOGOUT_URL,
    SSO_HOMR_URL
}
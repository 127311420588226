import { ROUTES } from "./app.router";
import { Route, Routes } from "react-router-dom";
import AuthorizeRoute from "./authorize.route";
import UnAuthorizeRoute from "./unauthorize.route";
import AuthorizedLayout from "../layouts/authorize.layout";
import UnAuthorizedLayout from "../layouts/unauthorize.layout";

const AppRouters = () => {
    const renderRoutes = (routes) => {
        return routes.map(({ component: Component, isAuthenticationRequired, path, children, showHeader }, key) => {
            const element = isAuthenticationRequired ? (
                <AuthorizeRoute>
                    <AuthorizedLayout showHeader={showHeader}>
                        <Component />
                    </AuthorizedLayout>
                </AuthorizeRoute>
            ) : (
                <UnAuthorizeRoute>
                    <UnAuthorizedLayout>
                        <Component />
                    </UnAuthorizedLayout>
                </UnAuthorizeRoute>
            );

            return (
                <Route path={path} key={key} element={element}>
                    {children && renderRoutes(children)}
                </Route>
            );
        });
    };

    return (
        <Routes>
            {renderRoutes(ROUTES)}
        </Routes>
    );
};

export default AppRouters;

import { Grid, Typography } from "@mui/material";
import { Oval } from "react-loader-spinner";
import { LoaderStyle } from "./loader-style";
import { CS } from "@src/assets/styles/common-styles";
import { COLORS } from '../../assets/styles/colors';

const Loader = (props) => {
    let loaderStyle = LoaderStyle();
    let { section, message } = props;
    message = message ? message : "Please be patient as our system is currently processing your request !";
    let commonStyle = CS();
    return (
        <>
            <Grid className={`${section ? loaderStyle.sectionLoader : loaderStyle.loaderWrapper}`}>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={`${commonStyle.fullHeight}`}
                >
                    <Grid item>
                        <Grid sx={{ width: 'fit-content', margin: 'auto', mb: '16px' }}>
                            <Oval height={50}
                                width={50}
                                color={COLORS.SECONDARY}
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor={COLORS.PRIMARY}
                                strokeWidth={4}
                                strokeWidthSecondary={3} />
                        </Grid>
                        <Grid>
                            <Typography className={commonStyle.fs9}>{message}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Loader;
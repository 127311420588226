import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { APPLICATION_ACCESS_ID, APPLICATION_ACCESS_KEY, REDIRECT_URL ,SSO_URL} from "../../envrionment";

const UnAuthorizeRoute = ({ children }) => {
    const location = useLocation();
    const { isLoggedIn } = useSelector((state) => state.authentication);
    useEffect(() => {
       
    }, []);
    const publicPaths = ["/validate/token"];
    if (isLoggedIn && !publicPaths.includes(location.pathname)) {
        const state = Math.floor(1000000000 + Math.random() * 9000000000);
        document.cookie = `state=${state}; path=/; max-age=1800`;
        let url = `${SSO_URL}?response_type=code&application_access_id=${APPLICATION_ACCESS_ID}&applicaiton_access_key=${APPLICATION_ACCESS_KEY}&redirect_url=${REDIRECT_URL}&state=${state}`;
        window.location.href = url;
    }
    if (publicPaths.includes(location.pathname)) {
        return children; 
    }
    return children;
};

export default UnAuthorizeRoute;


import React, { lazy } from "react";

const Search = lazy(() => import("../screens/authorized/search/search"));
const PageNotFound = lazy(() => import("../screens/unauthorized/error/404"));
const SearchResult = lazy(() => import("../screens/authorized/search/search_result"));
const SearchedHistory = lazy(() => import("../screens/authorized/search/searched_history"));
const Authorize = lazy(() => import("../screens/unauthorized/authorize"));
const ROUTES = [
    { path: "/", component: Search, isAuthenticationRequired: true, showHeader: true },
    { path: "/search/result", component: SearchResult , isAuthenticationRequired: true, showHeader: true },
    { path: "/search/history", component: SearchedHistory , isAuthenticationRequired: true, showHeader: true },
    { path: "/validate/token", component: Authorize, isAuthenticationRequired: false, showHeader: false },
    { path: "*", component: PageNotFound , isAuthenticationRequired: false , showHeader: false},
]
export {
    ROUTES
}